* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  max-width: min-content;
  margin: auto;
  padding-top: 0.2rem;

  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: #020c1bb3;
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: #64ffda1a;
  --pink: #f57dff;
  --blue: #57cbff;
  --sage-green: #bce6d1;
  --deep-teal: #008080;
  --warm-grey: #a9a9a9;
}

body,
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-navy);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
