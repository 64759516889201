.app-container {
  max-width: fit-content;
  text-align: center;
}

.app-container:first-child {
  color: var(--green);
  margin-bottom: 20px;
}

.app-container > h1 {
  padding-bottom: 1rem;
}

.tutorial-div {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  top: 70px;
  left: 130px;
}

.tutorial-div > h4 {
  color: var(--green);
  font-size: 1.2rem;
}
.overlay {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.overlay-content {
  display: grid;
  position: relative;
  color: var(--lightest-slate);
  padding: 4rem 20px 20px 20px;
}

.overlay-content > h2 {
  color: var();
}

.overlay-content > p {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 30%;
  margin: auto;
}
.close-button {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  margin-left: 20px;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.close-button:before,
.close-button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  margin-left: 20px;
  /* padding-left: 20px; */
  width: 2px;
  height: 40px;
  background-color: var(--lightest-slate);
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}

.step-list {
  list-style-type: none;
  padding-top: 20px;
  margin: auto;
}

.step {
  display: grid;
  grid-template-columns: 50px 1000px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.step-number {
  /* display: inline-block; */
  width: 30px;
  height: 30px;
  background-color: var(--navy);
  color: white;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 10px;
}

.step-description {
  /* display: grid; */
  /* vertical-align: middle; */
}

.step-description h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.step-description p {
  margin-top: 0;
}

.simulation-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  height: 100%;
  max-width: 100%;
}

.question-mark {
  color: var(--lightest-slate);
}

.question-mark::before {
  content: '?';
  font-size: 50px;
  font-weight: bold;
  transform: translate(-50%, -50%);
}

.question-mark:hover {
  color: var(--green);
  text-shadow: 0 0 10px var(--lightest-slate);
  opacity: 0.8;
  cursor: pointer;
}

.question-mark:hover::before {
  font-size: 55px;
  transition: all 0.3s ease;
}

.reset-container {
  position: absolute;
  font-size: 1.2rem;
  right: 70px;
  top: 70px;
  color: var(--green);
}

.reset-icon {
  width: 50px;
  height: 50px;
  fill: none;
  fill-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--lightest-slate);
}

.reset-icon:hover {
  stroke: var(--green);
  opacity: 0.8;
  transition: all 0.3s ease;
  width: 55px;
  height: 55px;
  cursor: pointer;
}

.waterWorld {
  background-color: transparent;
}

.legend-container {
  display: grid;
  width: max-content;
  grid-template-rows: 20px 1fr 1fr 1fr 1fr 1fr;
  grid-row: 2 / 3;
  background-color: var(--dark-navy);
  margin-left: 5%;
}

.legend-div {
  margin: auto;
}

.pop-up {
  position: absolute;
  padding: 10px;
  width: 300px;
  /* top: 100px;
  right: 120px; */
  color: var(--light-slate);
  border: solid;
  border-width: 1px;
  border-color: var(--dark-slate);
  background-color: var(--navy-shadow);
}

.pop-up-control {
  position: absolute;
  padding: 10px;
  width: 300px;
  color: var(--light-slate);
  border: solid;
  border-width: 1px;
  border-color: var(--dark-slate);
  background-color: var(--navy-shadow);
  top: 140px;
}

.temp-pH-label:hover {
  font-size: 1.3rem;
  opacity: 0.8;
  color: var(--green);
  transition: all 0.3s ease;
}

#water {
  top: 130px;
  right: 120px;
}

#proton {
  top: 270px;
  right: 120px;
}

#carbs {
  top: 380px;
  right: 120px;
}

#proteins {
  top: 500px;
  right: 120px;
}

#lipids {
  top: 620px;
  right: 120px;
}

.legend-div > p {
  padding-right: 0.8em;
}

.legend-water-particle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: blue;
  margin-left: 0.5em;
}

.legend-water-particle:hover {
  box-shadow: 0 0 10px var(--green);
  transform: scale(1.1);
  opacity: 0.8;
  transition: all 0.3s ease;
}

.clickbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.legend-proton-particle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--pink);
}

.legend-proton-particle:hover {
  box-shadow: 0 0 10px var(--green);
  transform: scale(1.1);
  opacity: 0.8;
  transition: all 0.3s ease;
}

.legend-carb-particle:hover {
  box-shadow: 0 0 10px var(--green);
  transform: scale(1.1);
  opacity: 0.8;
  transition: all 0.3s ease;
}

.legend-protein-particle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #008080;
  margin-left: 0.5em;
}

.legend-protein-particle:hover {
  box-shadow: 0 0 10px var(--green);
  transform: scale(1.1);
  opacity: 0.8;
  transition: all 0.3s ease;
}

.legend-lipid-particle {
  width: 30px;
  height: 15px;
  background-color: #a9a9a9;
  margin-left: 0.5em;
}

.legend-lipid-particle:hover {
  box-shadow: 0 0 10px var(--green);
  transform: scale(1.1);
  opacity: 0.8;
  transition: all 0.3s ease;
}

.waterContainer {
  grid-row: 2;
}

.controls-container {
  display: grid;
  grid-column: 2;
  grid-row-start: 1;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 20%;
  background-color: var(--dark-navy);
  justify-self: center;
  color: var(--green);
  height: 100%;
}

/* Controls headline */
.controls-container > :first-child {
  grid-column: 1;
  grid-row: 1;
  padding: 0.5em 0px 1em;
  align-self: center;
  font-size: 1.5rem;
}

/* temp slider */
.controls-container > :nth-child(2) {
  grid-column: 2;
  align-self: center;
}

/* pH slider */
.controls-container > :nth-child(3) {
  grid-column: 3;
  align-self: center;
}

/* button container */
.controls-container > :nth-child(4) {
  grid-column: 4;
  display: grid;
}

/* slider */
.row {
  padding-bottom: 0.4rem;
}

.row > label,
span {
  color: var(--lightest-slate);
  font-size: 1.2rem;
  /* font-weight: bold; */
}

.row > span {
  font-weight: bold;
  color: var(--green);
}

.text-container {
  grid-column: 1;
  grid-row: 2 / span 3;
  color: var(--green);
  width: 20rem;
  background-color: var(--dark-navy);
}

.legend-container {
  color: var(--green);
}

.feedback {
  display: grid;
  grid-template-rows: 50px 15% 15% 15%;
  height: 100%;
}

/* enzyme monitor */
.feedback > div:first-child {
  margin: auto;
  justify-self: center;
}

.heading {
  /* grid-row: 1 / span 2; */
  padding-left: 5px;
}

.feedback > div:not(:first-child) {
  padding: 0px 20px 0;
  color: var(--lightest-slate);
  justify-self: center;
}

/* Enzyme name in Feedback screen */
.feedback > div:not(:first-child) > p:first-child {
  color: var(--green);
  font-size: 1.2rem;
}

.feedback > div:not(:first-child) > p:nth-child(3) {
  padding-bottom: 10px;
}

.amylase > p:nth-child(2),
p:nth-child(3) {
  font-weight: bold;
}

.pepsin > p:nth-child(2) {
  font-weight: bold;
}

.lipase > p:nth-child(2) {
  font-weight: bold;
}

/* Pop ups */

#amylase {
  left: 250px;
}

#pepsin {
  left: 250px;
}

#lipase {
  left: 250px;
}

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.button-controls {
  display: grid;
  grid-template-columns: auto auto;
  /* display: flex; */
  /* flex-direction: column; */
}

.button-controls > h2 {
  width: 12rem;
}

.buttons {
  width: 12rem;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  height: var(--tab-height);
  padding: 5px 10px 2px;
  /* margin-bottom: 10px; */
  background-color: var(--dark-navy);
  color: var(--lightest-slate);
  font-family: var(--font-mono);
  font-size: 1.1rem;
  white-space: nowrap;
  border-radius: 0%;
  border: none;
  cursor: pointer;
}

.buttons:hover {
  color: var(--green);
  background-color: var(--light-navy);
}

.version-number {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem 1rem 0 0;
}

.icon-credits {
  position: absolute;
  right: 0;
  margin: 1rem 1rem 0 0;
  padding-bottom: 0.5rem;
  color: var(--lightest-slate);
  font-size: 0.9rem;
}

.icon-credits > a {
  text-decoration: none;
  color: var(--lightest-slate);
  font-weight: bolder;
}

.icon-credits > a:hover {
  color: var(--green);
}
